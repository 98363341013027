import React, { useState, useEffect, useRef } from 'react';
import { Document, Page, pdfjs } from 'react-pdf';
import { Button,Typography } from '@mui/material';
import '../Style/PDFViewer.css';


// Use a stable CDN for PDF.js worker
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.mjs`

  
const PDFViewer = ({ url, certificateId, user}) => {
  const [isLoading, setIsLoading] = useState(true);
  const [pdfData, setPdfData] = useState(null);
  const pdfRef = useRef(null);

  function formatDate(dateNumber) {
    const options = { year: 'numeric', month: 'long', day: 'numeric' };
    const date = new Date(dateNumber);
    return date.toLocaleDateString('en-US', options);
  }
  // const issuedDate = formatDate(user.workshop_date);
  const issuedDate = user.workshop_date;

  useEffect(() => {
    const fetchPdf = async () => {
      setIsLoading(true);
      try {
        const response = await fetch(url);
        if (response.ok) {
          const data = await response.blob();
          setPdfData(URL.createObjectURL(data)); // Convert blob to object URL
        } else {
          console.error('Failed to fetch PDF:', response.statusText);
        }
      } catch (error) {
        console.error('Error fetching PDF:', error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchPdf();
  }, [url]);
  
  const handleDownload = () => {
    // Implement your download logic here (e.g., using a library or file generation)
    const downloadUrl = `${url}`;
    window.open(downloadUrl, '_blank');
  };

  const handleLinkedIn=()=>{
    const workshopName = encodeURIComponent(user.workshop_name); // Changed this from replace to encode space properly
    const certiUrl= `${window.location.origin}/certificate?certificate_id=${certificateId}`;
    const LinkedInUrl = `https://www.linkedin.com/profile/add?name=${workshopName}&organizationId=76836334&certUrl=${encodeURIComponent(certiUrl)}`;

    const popupWindow = window.open(
      LinkedInUrl,
      'LinkedIn_Popup',
      'width=800,height=600,left=0,top=0'
    );
    if (popupWindow) {
      popupWindow.focus();
    }
  }

  const handleFacebook=()=>{
    const certiUrl= `${window.location.origin}/certificate?certificate_id=${certificateId}`;
    const facebookUrl=`https://www.facebook.com/sharer/sharer.php?u=${certiUrl}`;
    const popupWindow = window.open(
      facebookUrl,
      'Facebook_Popup',
      'width=800,height=600,left=0,top=0'
    );
    if (popupWindow) {
      popupWindow.focus();
    }
  }

  const handleTwitter=()=>{
    const workshopName = user.workshop_name.replace(/\s/g, '%20'); // Encode spaces
    const certiUrl= `${window.location.origin}/certificate?certificate_id=${certificateId}`
    const TwitterUrl=`https://x.com/intent/post?url=${encodeURIComponent(certiUrl)}%2F&text=${workshopName}%0A`
    const popupWindow = window.open(
      TwitterUrl,
      'Twitter_Popup',
      'width=800,height=600,left=0,top=0'
    );
    if (popupWindow) {
      popupWindow.focus();
    }
  }

  return (
    
    <div className="pdf-viewer">
      {isLoading && <div className="loading">Loading PDF...</div>}
      {pdfData && (
        <Document
          file={pdfData}
          onLoadError={(error) => console.error("Error loading PDF:", error)}
          ref={pdfRef}
        >
          <Page pageNumber={1} />
        </Document>
      )}
      <div className='right_handle_bar'>
        <div className='social_button'>
            <ul className='social_button_list'>
                <li className='Twitter' onClick={handleTwitter}>
                <svg id="Capa_1" viewBox="0 0 1226.37 1226.37" xmlns="http://www.w3.org/2000/svg"><path d="m727.348 519.284 446.727-519.284h-105.86l-387.893 450.887-309.809-450.887h-357.328l468.492 681.821-468.492 544.549h105.866l409.625-476.152 327.181 476.152h357.328l-485.863-707.086zm-144.998 168.544-47.468-67.894-377.686-540.24h162.604l304.797 435.991 47.468 67.894 396.2 566.721h-162.604l-323.311-462.446z"/></svg>
                </li>
                <li className='Facebook' onClick={handleFacebook}>
                <svg id="Capa_1"   viewBox="0 0 512 512"  xmlns="http://www.w3.org/2000/svg"><g><path d="m512 256c0 127.78-93.62 233.69-216 252.89v-178.89h59.65l11.35-74h-71v-48.02c0-20.25 9.92-39.98 41.72-39.98h32.28v-63s-29.3-5-57.31-5c-58.47 0-96.69 35.44-96.69 99.6v56.4h-65v74h65v178.89c-122.38-19.2-216-125.11-216-252.89 0-141.38 114.62-256 256-256s256 114.62 256 256z" fill="#1877f2"/><path d="m355.65 330 11.35-74h-71v-48.021c0-20.245 9.918-39.979 41.719-39.979h32.281v-63s-29.296-5-57.305-5c-58.476 0-96.695 35.44-96.695 99.6v56.4h-65v74h65v178.889c13.034 2.045 26.392 3.111 40 3.111s26.966-1.066 40-3.111v-178.889z" fill="#fff"/></g></svg>
                </li>
                <li className='LinkedIn' onClick={handleLinkedIn}>
                <svg  viewBox="0 0 176 176"  xmlns="http://www.w3.org/2000/svg"><g id="Layer_2" data-name="Layer 2"><g id="linkedin"><rect id="background" fill="#0077b5" height="176" rx="24" width="176"/><g id="icon" fill="#fff"><path d="m63.4 48a15 15 0 1 1 -15-15 15 15 0 0 1 15 15z"/><path d="m60 73v66.27a3.71 3.71 0 0 1 -3.71 3.73h-15.81a3.71 3.71 0 0 1 -3.72-3.72v-66.28a3.72 3.72 0 0 1 3.72-3.72h15.81a3.72 3.72 0 0 1 3.71 3.72z"/><path d="m142.64 107.5v32.08a3.41 3.41 0 0 1 -3.42 3.42h-17a3.41 3.41 0 0 1 -3.42-3.42v-31.09c0-4.64 1.36-20.32-12.13-20.32-10.45 0-12.58 10.73-13 15.55v35.86a3.42 3.42 0 0 1 -3.37 3.42h-16.42a3.41 3.41 0 0 1 -3.41-3.42v-66.87a3.41 3.41 0 0 1 3.41-3.42h16.42a3.42 3.42 0 0 1 3.42 3.42v5.78c3.88-5.82 9.63-10.31 21.9-10.31 27.18 0 27.02 25.38 27.02 39.32z"/></g></g></g></svg>
                </li>
                <li></li>
            </ul>
        </div>
        <div className='course_verification'>
            <div className="course-verification-meta">
                <div className="verification-title">{user.workshop_name}</div>
                </div>
            </div>



        <div className='Certificate_information'>
          <div className='course_issue_date'>
            <div><strong>Workshop Date:</strong></div>
            <div>{issuedDate}</div>
          </div>
          <Typography style={{margin:'2px 0', display:'flex',justifyContent:'space-between' }}>
            <strong>Certificate ID:</strong> {certificateId}
          </Typography>
          <Typography style={{margin:'2px 0', display:'flex',justifyContent:'space-between' }}>
            <strong>Name:</strong> {user.name}
          </Typography>
          <Typography style={{margin:'2px 0', display:'flex',justifyContent:'space-between' }}>
            <strong>Trainer:</strong> {user.Trainer}
          </Typography>
        </div>
        <div className='Course_info'></div>
        <div className="download_button">
          <Button variant="contained" onClick={handleDownload}  style={{fontFamily:'sans-serif', fontWeight:600}}>
            Download PDF
          </Button>
        </div>
      </div>
    </div>
  );
};

export default PDFViewer;
